var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NumberRange from "~/components/common/number-range.vue";
import { CaseInfoService } from "~/services/business-service/case-info.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { StrategyDistributeService } from "~/services/dataimp-service/strategy-distribute.service";
var StopCasePool = /** @class */ (function (_super) {
    __extends(StopCasePool, _super);
    function StopCasePool() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.selectionList = [];
        _this.searchModel = {
            principalId: "",
            batchNumber: "",
            personalName: "",
            certificateNo: "",
            caseNumber: "",
            cardNo: "",
            phone: "",
            stage: "",
            caseType: "",
            retireCaseDays: "",
            employerName: "",
            address: "",
            caseNumbers: "",
            certificateNos: "",
            phones: "",
            overdueAmtTotal: {
                min: "",
                max: "",
            },
            leftAmt: {
                min: "",
                max: "",
            },
            delegationDate: [],
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
            city: "",
            endCaseDate: [],
            stopTime: [],
        };
        _this.dataSet = [];
        _this.rules = {
            certificateNo: [{ min: 6, message: "请输入至少6位的身份证号", trigger: "blur" }],
            phone: [{ min: 4, message: "请输入至少4位的手机号", trigger: "blur" }],
            cardNo: [{ min: 6, message: "请输入至少6位的卡号", trigger: "blur" }],
        };
        return _this;
    }
    StopCasePool.prototype.mounted = function () {
        this.refreshData();
    };
    StopCasePool.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    StopCasePool.prototype.batchClick = function () {
        var _this = this;
        if (this.selectionList.length === 0) {
            this.$message("请选择要复调的案件");
        }
        else {
            this.$confirm("您确定要复调选择的案件吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(function () {
                _this.loading.state = true;
                var idList = _this.selectionList.map(function (v) { return v.id; });
                _this.caseInfoService.backCollectCasePool(idList).subscribe(function (data) {
                    _this.$message.success("复调操作成功!");
                    _this.loading.state = false;
                    _this.refreshData();
                }, function (err) {
                    _this.loading.state = false;
                    _this.$message.error(err.msg);
                });
            });
        }
    };
    StopCasePool.prototype.handleResetForm = function () {
        this.searchModel.principalId = "";
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.leftAmt = {};
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    /**
     * 策略分配
     */
    StopCasePool.prototype.onStrategyAllocation = function () {
        var _this = this;
        // this.dialog.strategyAllocation = true;
        this.$confirm("确定执行策略分案?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(function () {
            _this.loading.state = true;
            _this.strategyDistributeService.manualExecuteStrategy("STOPPING", _this.loading).subscribe(function (data) {
                _this.$message({
                    type: "success",
                    message: "正在进行策略分案，请稍后刷新页面!",
                });
            });
        })
            .catch(function () {
            _this.$message({
                type: "info",
                message: "已取消策略分案",
            });
        });
    };
    StopCasePool.prototype.refreshData = function () {
        var _this = this;
        this.caseInfoService.queryStopCases(this.removeEmptyProperties(this.searchModel), this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], StopCasePool.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], StopCasePool.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], StopCasePool.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(StrategyDistributeService)
    ], StopCasePool.prototype, "strategyDistributeService", void 0);
    StopCasePool = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch,
            },
        })
    ], StopCasePool);
    return StopCasePool;
}(Vue));
export default StopCasePool;
